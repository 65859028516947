@import '../../assets/mixins.scss', '../../assets/variables';

header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1440px;
    width: 100%;
    align-items: center;
    padding: 94px 84px 113px 84px;
    margin-bottom: -30px;
    background-color: $cream-white;
    a {
        display: flex;
    }
}

.name-and-title {
    display: flex;
    flex-direction: column;
    margin-left: 28px;
    gap: 15px;
    flex: 1;
    span {
        color: #000;
    }
    .name {
        @include MidFont;
    }
    .job {
        @include SmallFontAlt;
    }
}

@include tabletQuery {
    header {
        align-items: flex-start;
        padding: 20px 30px;
        padding-bottom: 57px;
        margin-bottom: -30px;
        height: auto;
        background-color: $cream-white;
        a {
            display: flex;
        }
    }
    .name-and-title {
        display: none;
    }
    #main-logo {
        width: 41px;
        height: 41px;
    }
}
