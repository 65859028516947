@import '../../assets/fonts/font-faces.scss', '../../assets/mixins.scss';

.catch {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: 100%;
    height: 221px;
    padding: 60px 167px;
    border-radius: 30px;
    background: #dfede0;
}

.expert {
    display: flex;
    width: 100%;
    position: absolute;
    top: 0;
    left: 267px;
    transition: all cubic-bezier(0.35, 0.11, 0, 0.77) 1s;
    opacity: 0;
    transform: translateY(25px);
}

.slideIn {
    opacity: 1;
    transform: translateY(0px);
}

.link-external {
    @include TinyFont;
    display: block;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #fff;
    background: #f7b641;
    padding-top: 4px;
    padding-bottom: 6px;
    color: #fff;
    text-align: center;

    &:hover {
        .malt {
            @include TinyFontBold;
            text-decoration: underline;
        }
    }
}

#expert__catch {
    margin-left: 5px;
}

@include tabletQuery {
    .catch {
        height: 187px;
        justify-content: center;
        align-items: flex-start;
        padding: 20px 34px;
    }
    #home__subtitle {
        flex-direction: column;
        gap: 9px;
    }
    .expert {
        top: 33px;
        left: 105px;
    }
    .link-external {
        width: 100%;
        padding-top: 4px;
        padding-bottom: 6px;

        &:hover {
            .malt {
                @include TinyFontBold;
                text-decoration: underline;
            }
        }
    }
    #expert__catch {
        margin-left: 0px;
    }
}
