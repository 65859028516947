@import '../../assets/mixins.scss';

.skill-bar {
    position: relative;
    left: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: fit-content;
    opacity: 0.7;
    transition: all ease-in-out 300ms;
    &:hover {
        opacity: 1;
    }

    &__title {
        width: auto;
        height: auto;
        h3 {
            position: absolute;
            top: 30px;
            left: 50px;
            color: black;
            @include SmallFontAlt;
            margin-bottom: 31px;
            &:hover {
                cursor: url('../../assets/pictos/paw-cursor-01-hover.png'), auto;
            }
        }
    }

    .skill-bar__title__arrow {
        display: block;
        position: absolute;
        top: 34px;
        left: 200px;
        & path {
            stroke: black;
            stroke-width: 5px;
        }

        transition: transform ease-in-out 300ms;
        transform: rotate(-90deg);
        &:hover {
            cursor: url('../../assets/pictos/paw-cursor-01-hover.png'), auto;
        }
        &--open {
            transform-origin: center;
            transform: rotate(0deg);
        }
    }

    &__title-bg {
        width: 534px;
        height: 84px;
    }
    &__content {
        display: flex;
        color: #000;
        @include TinyFont;
        width: 534px;
        height: auto;
        line-height: 24px;
        margin-top: 5px;
        margin-left: 60px;
        margin-bottom: 41px;
        padding-left: 65px;
        overflow: hidden;
        transition: all ease-in-out 300ms;
    }
}
.reverse {
    align-items: flex-start;
    width: 533px;

    h3 {
        position: absolute;
        top: 30px;
        left: 330px;
        color: black;
        @include SmallFontAlt;
        margin-bottom: 31px;
        &:hover {
            cursor: url('../../assets/pictos/paw-cursor-01-hover.png'), auto;
        }
    }

    .skill-bar__content {
        padding-left: 45px;
        width: auto;
    }
    .skill-bar__title__arrow {
        display: block;
        position: absolute;
        top: 34px;
        left: 280px;
        stroke: black;
        stroke-width: 5px;
        transition: transform ease-in-out 300ms;
        transform: rotate(-90deg);
        &--open {
            transform-origin: center;
            transform: rotate(0deg);
        }
    }
    .sb__c {
        &--open {
            min-height: 96px;
            height: fit-content;
            animation: slideInFromBottom 500ms forwards;
        }
        &--closed {
            height: 0;
            margin-top: 5px;
            margin-left: 0px;
            margin-bottom: 0px;
        }
    }
}

.sb__c {
    &--open {
        min-height: 96px;
        height: fit-content;
        animation: slideInFromBottom 500ms forwards;
    }
    &--closed {
        height: 0;
        margin-top: 5px;
        margin-left: 40px;
        margin-bottom: 0px;
    }
}

@keyframes slideInFromBottom {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    60% {
        opacity: 0;
    }
    80% {
        opacity: 0.3;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.pink {
    background: #f1a89a;
}

.blue {
    background: #d9cce6;
}

.orange {
    background: #f7a82f;
}

@include tabletQuery {
    .skill-bar {
        display: flex;
        h3 {
            position: absolute;
            top: 30px;
            left: auto;
            right: 360px;
        }
        .skill-bar__title__arrow {
            display: block;
            position: absolute;
            top: 32px;
            left: auto;
            right: 310px;
        }
    }
    .reverse {
        align-items: flex-start;
        width: 100%;
        h3 {
            left: 330px;
        }
        .skill-bar__content {
            padding-left: 20px;
            width: 100%;
        }
        .skill-bar__title__arrow {
            display: block;
            position: absolute;
            top: 34px;
            left: 280px;
        }
        .sb__c {
            &--open {
                width: 100%;
                min-height: 96px;
                height: fit-content;
                animation: slideInFromBottom 500ms forwards;
            }
            &--closed {
                height: 0;
                margin-top: 5px;
                margin-left: 0px;
                margin-bottom: 0px;
            }
        }
    }
}

@include mobileQuery {
    .skill-bar {
        display: flex;

        h3 {
            position: absolute;
            top: 28px;
            left: auto;
            right: 120px;
        }
        .skill-bar__title__arrow {
            display: block;
            position: absolute;
            top: 30px;
            left: auto;
            right: 78px;
        }
        &__content {
            width: 80%;
            height: auto;
            margin-bottom: 21px;
        }
    }
    .sb__c {
        &--open {
            min-height: 96px;
            height: fit-content;
            transform: translate(50%);
            animation: slideInFromBottom 500ms forwards;
        }
        &--closed {
            height: 0;
            margin-top: 5px;
            margin-left: 40px;
            margin-bottom: 0px;
        }
    }

    .reverse {
        align-items: flex-start;
        width: 100%;
        h3 {
            left: 100px;
        }
        .skill-bar__content {
            padding-left: 20px;
            width: 100%;
        }
        .skill-bar__title__arrow {
            display: block;
            position: absolute;
            top: 34px;
            left: 60px;
        }
        .sb__c {
            &--open {
                width: 100%;
                min-height: 96px;
                height: fit-content;
                animation: slideInFromBottom 500ms forwards;
            }
            &--closed {
                height: 0;
                margin-top: 5px;
                margin-left: 0px;
                margin-bottom: 0px;
            }
        }
    }
}
