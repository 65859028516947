@font-face {
    font-family: 'Wendy One';
    src: url(./Wendy_One/WendyOne-Regular.ttf);
}

@font-face {
    font-family: 'Lato';
    src: url('./Lato/Lato-Regular.ttf');
}

@font-face {
    font-family: 'Lato Semi-Bold';
    src: url('./Lato/Lato-Bold.ttf');
}
