@import '../../assets/variables', '../../assets/keyframes',
    '../../assets/mixins';

.article {
    &__image-container {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-self: center;
        align-items: center;
        box-sizing: border-box;
        height: 415px;
        width: 912px;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 20px 0;

        video {
            display: flex;
            justify-self: center;
            align-self: center;
            max-width: 100%;
            max-height: 100%;
            height: 100%;
            object-fit: contain;
        }
        .video {
            &__wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .carousel-button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: $dark-grey;
            stroke-width: 2px;
            stroke-opacity: 0.7;
            stroke: $cream-white;
            border: none;
            background: none;
            cursor: pointer;
            z-index: 3;
        }

        .carousel-button.left {
            left: 4%;
        }

        .carousel-button.right {
            right: 4%;
        }
    }
    &__image {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        position: absolute;
        margin: 0;
        height: 415px;
        width: 912px;
        border-radius: 20px;
        object-fit: contain;
        background-color: $cream-white;
        opacity: 1;
        &-center {
            transition: all 600ms ease-in;

            z-index: 2;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
        &-previous {
            transition: all 600ms ease-in;
            height: 379px;
            width: 834px;
            left: -50%;
            opacity: 0;
            animation: FadeInPicture 600ms ease-in-out forwards;
            animation-delay: 600ms;
            transform: translateX(-50%) translateY(-50%);
            pointer-events: none;
            &-preload {
                transition: none;
                left: -20%;
                opacity: 0;
                transform: translateX(-50%) translateY(-50%);
            }
        }
        &-next {
            transition: all 600ms ease-in;
            height: 379px;
            width: 834px;
            left: 150%;
            opacity: 0;
            animation: FadeInPicture 600ms ease-out forwards;
            animation-delay: 500ms;
            transform: translateX(-50%) translateY(-50%);
            pointer-events: none;
            &-preload {
                transition: none;
                left: 120%;
                opacity: 0;
                transform: translateX(-50%) translateY(-50%);
            }
        }
        &-unselected {
            display: none;
            transform: translateX(0) translateY(-200%);
            opacity: 0;
        }
    }
}

.carousel-pagination {
    &__wrapper {
        display: flex;
        width: auto;
        height: auto;
        position: absolute;
        top: 107%;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%);
        gap: 12px;
    }
    &__item {
        cursor: url('../../assets/pictos/paw-cursor-01-hover.png'), auto;
        display: flex;
        width: 25px;
        height: 3px;
        background-color: darken($middle-grey, 10%);
        transition: all ease-in-out 300ms;
        &:hover {
            background-color: $dark-grey;
            opacity: 0.5;
        }
        &--small {
            width: 10px;
        }
        &--selected {
            display: flex;
            width: 45px;
            background-color: $dark-grey;
        }
    }
}

.project-carousel_animation {
    display: flex;
    position: relative;
    width: inherit;
    height: inherit;
    &--hide {
        opacity: 0;
    }
    &--show {
        animation: popInFadeOut 300ms forwards ease-in-out;
    }
}

@keyframes popInFadeOut {
    0% {
        opacity: 0;
        transform: translateY(-5%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@include tabletQuery {
    .article {
        &__image-container {
            height: auto;
            width: 100%;
            margin: 0 auto;
            margin-top: 20px;
            margin-bottom: 20px;
            padding: 20px 0;
        }
        &__image {
            width: 90vw;
            &-previous {
                width: 70vw;
            }
            &-next {
                width: 70vw;
            }
        }
    }
}

@include mobileQuery {
    .article {
        &__image-container {
            .carousel-button {
                position: absolute;
                top: 50%;
                transform: translateY(-50%) scale(0.5);
            }

            .carousel-button.left {
                left: 2%;
            }

            .carousel-button.right {
                right: 2%;
            }
        }
        &__image {
            height: 85vw;
            width: 85vw;

            &-previous {
                height: 70vw;
                width: 70vw;
            }
            &-next {
                height: 70vw;
                width: 70vw;
                left: 150%;
            }
        }
    }
    .carousel-pagination {
        &__wrapper {
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;
            top: 110%;
        }
    }
}
