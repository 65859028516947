$dark-green: #195a1e;
$middle-grey: #dfede0;
$dark-grey: #686868;
$orange: #ea8d00;
$pink: #e48471;
$blue: #cbb4e1;
$cream-white: #fffef0;
$dark-blue: #7952a0;

$tablet-query: 960px;
$mobile-query: 545px;
