@import '../../assets/mixins.scss', '../../assets/variables';

.navbar {
    &__desktop {
        display: flex;
        justify-self: flex-end;
    }
}

.link {
    color: #000;
    @include TinyFont;
    text-decoration: none;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &--active {
        @include TinyFontBold;
        color: $dark-green;
        + .link__dot {
            circle {
                fill: $dark-green;
                transform: scale(1);
            }
        }
    }
    &:hover {
        text-decoration: underline;
        text-underline-offset: 5px;
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-left: 28px;

        svg {
            margin-top: 10px;
            transition: all ease-in-out 300ms;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        circle {
            transform: scale(0);
            transform-origin: center;
            transition: all ease-in-out 300ms;
        }
    }
}
