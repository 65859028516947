@import '../../assets/variables', '../../assets/mixins';

.cat-tail-container {
    display: block;
    position: relative;
    top: 0;
    left: 0%;
    width: 100%;
    height: 85px;
    overflow: visible;
    z-index: -1;
}

.cat-tail {
    display: block;
    height: auto;
    width: auto;
    position: absolute;
    opacity: 0;
    z-index: 1;
    top: -20px;
    left: -530%;
    transition: all ease-out 800ms;
}

.cat-tail-bottom {
    display: block;
    width: 100%;
    height: 30px;
    margin-bottom: 33px;
    transition: all ease-in-out 800ms;
    &-pink {
        background-color: $pink;
    }
    &-blue {
        background-color: $blue;
    }
    &-orange {
        background-color: $orange;
    }
    &-white {
        background-color: $cream-white;
    }
}

.selected-tail {
    z-index: 2;
    opacity: 1;
    transform-origin: right;
    // animation: catTailIntro 600ms forwards;
}

@keyframes catTailIntro {
    0% {
        transform: translateY(-50%) rotate(-25deg);
    }
    50% {
        transform: translateY(15%) rotate(5deg);
    }
    75% {
        transform: translateY(0) rotate(0deg);
    }
    100% {
        transform: translateY(0);
    }
}

@include tabletQuery {
    .cat-tail {
        top: 0px;
        left: -878%;
    }
}
