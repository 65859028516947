@keyframes fadeInPage {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes FadeInPicture {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}
