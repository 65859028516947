@import './fonts/font-faces.scss', './variables';

@mixin BigFont {
    font-family: 'Wendy One';
    font-size: 70px;
    line-height: 70px;
}

@mixin BigFontMobile {
    font-family: 'Wendy One';
    font-size: 60px;
    line-height: 60px;
}

@mixin MidFont {
    font-family: 'Wendy One';
    font-size: 40px;
    line-height: 40px;
}

@mixin SmallFont {
    font-family: 'Wendy One';
    font-size: 28px;
    line-height: 28px;
}

@mixin SmallFontAlt {
    font-family: 'Lato';
    font-size: 28px;
    line-height: 28px;
}

@mixin SmallFontAltBold {
    font-family: 'Lato Semi-Bold';
    font-size: 28px;
    line-height: 28px;
}

@mixin TinyFont {
    font-family: 'Lato';
    font-size: 16px;
    line-height: 24px;
}

@mixin TinyFontBold {
    font-family: 'Lato Semi-Bold';
    font-size: 16px;
    line-height: 24px;
}

@mixin tabletQuery($max-width: $tablet-query) {
    @media screen and (max-width: $max-width) {
        @content;
    }
}

@mixin mobileQuery($max-width: $mobile-query) {
    @media screen and (max-width: $max-width) {
        @content;
    }
}
