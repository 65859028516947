@import '../../assets/variables', '../../assets/keyframes';

.modal-wrapper {
    position: static;
    top: 0px;
    left: 0;
    z-index: 1000;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .article__image-container {
        height: 80%;
        width: 100vw;
    }
    .article__image {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        position: absolute;
        margin: 0;
        height: 100%;
        width: 80%;
        &-center {
            z-index: 2;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
        &-previous {
            height: 60%;
            width: 60%;
            left: -30%;
            opacity: 0;
            animation: FadeInPicture 600ms ease-in-out forwards;
            animation-delay: 600ms;
            transform: translateX(-50%) translateY(-50%);
            pointer-events: none;
            &-preload {
                left: -20%;
                opacity: 0;
                transform: translateX(-50%) translateY(-50%);
            }
        }
        &-next {
            height: 60%;
            width: 60%;
            left: 130%;
            opacity: 0;
            animation: FadeInPicture 600ms ease-in-out forwards;
            animation-delay: 600ms;
            transform: translateX(-50%) translateY(-50%);
            pointer-events: none;
            &-preload {
                left: 120%;
                opacity: 0;
                transform: translateX(-50%) translateY(-50%);
            }
        }
        &-unselected {
            display: none;
            transform: translateX(0) translateY(-200%);
            opacity: 0;
        }
    }
    .carousel-button.left {
        left: 15%;
    }

    .carousel-button.right {
        right: 15%;
    }
    .carousel-pagination {
        &__wrapper {
            padding: 20px;
            background-color: $cream-white;
            top: 101%;
            border-radius: 10px;
        }
    }
}

.modal-close {
    position: fixed;
    top: 20px;
    right: 9.5%;
    font-size: 65px;
    width: 72px;
    color: black;
    background: none;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1100;
}

.full-view-image {
    /* Styles for full-view images/videos */
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
}
