@import '../../assets/mixins.scss', '../../assets/variables';

footer {
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 14px;
    max-width: 1440px;
    width: 100%;
    margin-top: -30px;
    padding-top: 90px;
    padding-bottom: 60px;
    background-color: $cream-white;
    p {
        @include TinyFont;
    }
}

.footer-insta-btn {
    svg {
        path {
            fill: black;
            transition: fill ease-in-out 100ms;
        }
        &:hover {
            path {
                fill: $dark-grey;
            }
        }
    }
}
