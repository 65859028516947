@import '../assets/mixins';

.presentation {
    position: relative;
    display: flex;
    width: inherit;
    min-height: 526px;
    justify-content: space-between;
    flex-direction: row;
    padding: 61px 0;
}

.show-more {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 60px;
    margin-bottom: 0;
}

@include tabletQuery {
    .presentation {
        flex-direction: column;
    }
    .show-more {
        margin: 0;
    }
}
