@import '../../assets/mixins';

.skill-section {
    width: 40%;
    max-width: 534px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    justify-self: flex-end;
}

@include tabletQuery {
    .skill-section {
        top: 536px;
        width: 100%;
        max-width: 100%;
    }
}
