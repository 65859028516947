@import '../../assets/mixins.scss';

.project {
    &__selected-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    &__carousel {
        width: 100%;
        height: 475px;
        display: flex;
        align-items: center;
        position: relative;
        overflow: visible;
        margin: auto;
    }

    &__content {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 1250px;
        justify-content: space-around;
        align-items: flex-start;
        margin: 50px 100px;
        padding: 0 10%;
        gap: 5%;
        @include TinyFont;

        &__description-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        &--description {
            margin-bottom: 16px;
        }

        ul {
            list-style-type: disc;
            list-style-position: outside;
            padding: 0;
            align-self: flex-start;

            li {
                margin-bottom: 0.5rem;
            }
        }

        &__skills {
            display: flex;
            flex-direction: column;
            width: 80%;
            h4 {
                @include SmallFontAltBold;

                margin-bottom: 18px;
            }
            &-list {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
            }
            &__title {
                display: flex;
                gap: 15px;
                margin-left: -18px;
            }
        }
    }
}

.open-modal-button {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: flex-end;
    z-index: 3;
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 72px;
    right: 295px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    cursor: pointer;
    transition: all ease-in-out 300ms;
    svg {
        width: inherit;
        height: inherit;
        path {
            fill: white;
        }
    }

    &:hover {
        transform: scale(1.1);
        transform-origin: center;
        opacity: 0.7;
    }
}

@media screen and (max-width: 1157px) {
    .open-modal-button {
        position: absolute;
        right: 20%;
    }
}

@include tabletQuery {
    .project {
        &__content {
            padding: 0 5%;
        }
    }
    .open-modal-button {
        right: 10%;
    }
}

@include mobileQuery {
    .project {
        &__carousel {
            width: 90vw;
            height: 90vw;
            margin: 20px;
        }
        &__content {
            align-items: center;
            flex-direction: column;
            margin: 30px 0;
            padding: 0 20px;
            width: 90%;
            &__skills {
                margin-top: 30px;
            }
        }
    }
    .open-modal-button {
        display: none;
    }
}
