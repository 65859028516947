@import '../assets/mixins';

.errorPage {
    display: flex;
    align-items: center;
    height: 1450px;
    position: relative;
    top: 0;
    .linkBtn {
        width: 164px;
        background-image: url('../assets/images/linbtn404.svg');
        &:hover {
            background-image: url('../assets/images/linbtn404.svg');
            opacity: 0.7;
        }
    }
}

.error {
    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: fit-content;
        position: absolute;
        z-index: 2;
        left: 10%;
        top: 50px;
        .catch {
            padding: 0;
            text-align: right;
            align-items: end;
            justify-content: center;
        }
    }
}

.big-mandouille {
    z-index: 0;
    display: block;
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50px;
    transform: translate(-50%);
}

@include tabletQuery {
    .errorPage {
        height: fit-content;
    }
    .error {
        &__text {
            position: static;
            left: 0%;
            top: 0px;
        }
    }
    .big-mandouille {
        position: static;
        transform: translate(0);
        margin-top: 50px;
        margin-bottom: -70px;
    }
}
