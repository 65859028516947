@import '../assets/variables', '../assets/mixins';

.cvContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    min-height: 435px;
    margin-bottom: 150px;
    width: 100%;
    .skill-bar {
        justify-content: flex-end;
    }
    &__left {
        display: flex;
        flex-direction: column;
    }
    &__right {
        position: absolute;
        left: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.id-kids {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -20px;

    margin-top: 12px;
    margin-bottom: 12px;
    span {
        @include TinyFontBold;
        display: block;
        margin-right: 6px;
        width: fit-content;
        padding: 4px;
        border: #1b3b23 solid 2px;
    }
}

.sf__container {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 577px;
    height: fit-content;
    padding: 92px 77px;
    border-radius: 10px;
    background: $cream-white;
    h3 {
        @include SmallFontAltBold;
        color: $dark-blue;
        margin-bottom: 30px;
    }
    ul {
        list-style-type: disc;
        margin-left: 20px;
    }
    li {
        @include TinyFont;
        line-height: 24px;
        margin-bottom: 10px;
    }
    .li--bolded {
        @include TinyFontBold;
    }
    strong {
        @include TinyFontBold;
    }
}

.cv__languages {
    @include TinyFontBold;
    text-align: center;
    display: flex;
    margin-top: 79px;
    height: 190px;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    &__cat {
        position: absolute;
        &--big {
            z-index: -1;
            position: absolute;
            bottom: -80px;
        }
    }
    ul {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 8px;
    }
}

@include tabletQuery {
    .cvContent {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        min-height: 435px;
        width: 100%;
        .skill-bar {
            justify-content: flex-end;
        }
        &__left {
            align-self: flex-start;
            display: flex;
            flex-direction: column;
        }
        &__right {
            position: static;
            width: 95%;
            margin: auto;
            margin-top: 55px;
        }
    }

    .sf__container {
        width: 100%;
        height: 464px;
        margin: auto;
        border-radius: 10px;
        background: $cream-white;
        h3 {
            @include SmallFontAltBold;
            color: $dark-blue;
            margin-bottom: 30px;
        }
        ul {
            list-style-type: disc;
            margin-left: 20px;
        }
        li {
            @include TinyFont;
            line-height: 24px;
        }
        .li--bolded {
            @include TinyFontBold;
        }
    }
    .cv__languages {
        margin-bottom: 60px;
        height: 190px;
        width: 100%;
    }
}

@media screen and (max-width: 530px) {
    .sf__container {
        width: 100%;
    }
    .cv__languages {
        margin-bottom: 100px;
        height: 190px;
        width: 100%;

        &__cat {
            bottom: 170px;
            &--big {
                bottom: -279px;
            }
        }
        ul {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-bottom: 8px;
        }
    }
}

.aspi-pro {
    margin-top: 30px;
}
