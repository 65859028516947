@import '../../assets/mixins.scss';

.myself {
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 60%;
    &__content {
        z-index: 1;
        position: absolute;
        top: 10px;
        left: 18%;
        display: flex;
        width: 38%;
        flex-direction: column;
        text-align: start;

        p {
            line-height: 20px;
            color: #000;
            @include TinyFont;
            margin-bottom: 20px;
        }
    }
    &__picture {
        min-width: 200px;
        width: 50%;
        max-width: 281px;
        position: absolute;
        left: 55%;
    }
}

@include tabletQuery {
    .myself {
        position: static;
        flex-direction: column;
        margin: auto;
        width: 61%;
        height: fit-content;
        margin-bottom: 40px;

        &__content {
            z-index: 1;
            margin: auto;
            position: static;
            top: 10px;
            left: 18%;
            display: flex;
            width: 100%;
            flex-direction: column;
            text-align: start;

            p {
                line-height: 20px;
                color: #000;
                @include TinyFont;
                margin-bottom: 20px;
            }
        }
        &__picture {
            min-width: 181px;
            width: 181px;
            max-width: 281px;
            position: relative;
            left: 28%;
            top: -8px;
        }
    }
}
