@import '../assets/mixins.scss';

.timeline-component {
    display: flex;
    flex-direction: column;
    padding: 0 120px;
    margin-bottom: 50px;
    text-align: center;
    h3 {
        @include TinyFontBold;
        margin-bottom: 20px;
    }
}

@include mobileQuery {
    .timeline-component {
        padding: 0 20px;
    }
}
