@import '../assets/mixins.scss', '../assets/variables';

.portfolio {
    &__nav {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 80%;
        max-height: 10%;
        max-width: 1200px;
        margin-left: 147px;
        padding: 0 10px;
        margin-bottom: 14px;
        @include TinyFont;
        .cat {
            color: $dark-grey;
            margin-left: 10px;
            &:not(:last-child)::after {
                content: '|';
                margin-left: 10px;
                text-decoration: none;
            }
            &:hover {
                cursor: url('../assets/pictos/paw-cursor-01-hover.png'), auto;
                text-decoration: underline;
                text-underline-offset: 5px;
            }
        }
        .selected {
            @include TinyFontBold;
            color: black;
        }
        &__item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            &--selected {
                margin-top: 11px;
                opacity: 1;
                transform: scale(1);
            }
            &--hidden {
                opacity: 0;
                margin-top: 11px;
                transform: scale(0);
            }
        }
    }
}

@include tabletQuery {
    .portfolio {
        &__nav {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            max-height: 10%;
            max-width: 1200px;
            margin-left: 20px;
            padding: 0 10px;
            margin-bottom: 14px;
            @include TinyFont;
            .cat {
                width: fit-content;
                display: flex;
                color: $dark-grey;
                margin-left: 10px;
                &:not(:last-child)::after {
                    content: '|';
                    margin-left: 10px;
                    text-decoration: none;
                }
                &:hover {
                    cursor: url('../assets/pictos/paw-cursor-01-hover.png'),
                        auto;
                    text-decoration: underline;
                    text-underline-offset: 5px;
                }
            }
            .selected {
                @include TinyFontBold;
                color: black;
            }
            &__item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &--selected {
                    margin-top: 11px;
                    opacity: 1;
                    transform: scale(1);
                }
                &--hidden {
                    opacity: 0;
                    margin-top: 11px;
                    transform: scale(0);
                }
            }
        }
    }
}

.selection-point {
    transition: all ease-in-out 300ms;
}

.project {
    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    &__selection-wrapper {
        position: relative;
        display: flex;
        width: 100%;
        height: 445px;
        margin-top: 30px;
    }

    &__selection {
        position: absolute;
        display: flex;
        margin: 0 40px;
        width: 100%;
        justify-content: flex-start;
        top: 0px;
        left: calc(50% - 117px);
        transition: all 500ms ease-in-out;
        gap: 40px;
    }

    &__header {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        min-width: 185px;
        max-width: 185px;
        height: 377px;
        cursor: url('../assets/pictos/paw-cursor-01-hover.png'), auto;
        transition: all 800ms ease-in-out;
        opacity: 0.7;
        &:hover {
            transform: scale(1.1);
            opacity: 1; /* Zoom sur le projet lors du survol */
        }
    }

    &__title {
        @include SmallFontAltBold;
        text-align: center;
    }

    &__subtitle {
        @include SmallFont;
        text-align: center;
        margin-top: 53px;
        margin-bottom: 18px;
    }

    &__selected {
        opacity: 1;
    }

    &__selected .cat-tail {
        opacity: 1;
    }
}

.project__picto {
    position: absolute;
    top: 60%;
    width: 60px;
    height: 60px;
    margin-top: 20px;
}

.pinkBgCat {
    color: black;
    background-image: url('../assets/images/catBg.svg');
    &--mobile {
        color: black;
        background-image: url('../assets/images/catBg-mobile.svg');
        .project__picto {
            width: 34px;
            height: 34px;
        }
    }
}

.blueBgCat {
    color: black;
    background-image: url('../assets/images/catBgBlue.svg');
    &--mobile {
        color: black;
        background-image: url('../assets/images/catBgBlue-mobile.svg');
        .project__picto {
            width: 34px;
            height: 34px;
        }
    }
}

.orangeBgCat {
    color: black;
    background-image: url('../assets/images/catBgOrange.svg');
    &--mobile {
        color: black;
        background-image: url('../assets/images/catBgOrange-mobile.svg');
        .project__picto {
            width: 34px;
            height: 34px;
        }
    }
}

.whiteBgCat {
    color: black;
    background-image: url('../assets/images/catBgWhite.svg');
    &--mobile {
        color: black;
        background-image: url('../assets/images/catBgWhite-mobile.svg');
        .project__picto {
            width: 34px;
            height: 34px;
        }
    }
}

@include tabletQuery {
    .project {
        &__selection {
            position: absolute;
            display: flex;
            margin: 0 14px;
            width: 100%;
            justify-content: flex-start;
            top: 0px;
            left: calc(50% - 58px);
            transition: all 500ms ease-in-out;
            gap: 40px;
        }
        &__selection-wrapper {
            height: 365px;
            margin-top: 14px;
            margin-bottom: 24px;
        }
        &__header {
            min-width: 116px;
            max-width: 116px;
            transform: scale(1.2);
            width: 116px;
            height: 237px;
            justify-content: center;
            align-items: center;
            text-align: center;
            h3 {
                width: 90%;
            }
            &:hover {
                transform: scale(1.3);
                opacity: 1; /* Zoom sur le projet lors du survol */
            }
        }
        &__title {
            @include TinyFontBold;
        }
        &__subtitle {
            padding: 0 20px;
            margin-top: 0px;
        }
    }
}
